import React from "react";
import styles from "./CarouselClient.module.css";
import { Link } from "react-scroll";
import {
  IconsContainerClient,
  PackageWrapClient,
} from "./CarouselElementsClient";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import {
//   CarouselContainer,
//   WrapperContent,
//   WrapperImage,
//   BtnContent,
//   StyledSlider,
// } from "./CarouselElementsClient";

const CarouselClient = () => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 2000,
  //   autoplaySpeed: 10000,
  //   cssEase: "linear",
  //   arrows: false,
  //   swipe: false,
  // };

  return (
    <>
      <div className={styles.video_container} id="home">
        <div height="400" width="1920" className={styles.overlay}></div>
        <video
          className="video-background"
          src="/images/video.mp4"
          autoPlay
          loop
          muted
          playsInline
          height="400"
          width="1920"
        />
        <p>
          CAUTĂ TRANSPORTUL
          <br /> POTRIVIT PENTRU TINE
        </p>
        <Link className={styles.link} to="form">
          CONTACTEAZĂ-NE
        </Link>
      </div>
      {/* <CarouselContainer id="home">
        <StyledSlider {...settings}>
          <WrapperImage>
            <LazyLoad>
              <img
                src="/images/carousel1.png"
                width="1920"
                height="570"
                alt="First Carousel"
              />
            </LazyLoad>
          </WrapperImage>
          <WrapperImage>
            <LazyLoad>
              <img
                src="/images/carousel2.png"
                width="1920"
                height="570"
                alt="Second Carousel"
              />
            </LazyLoad>
          </WrapperImage>
          <WrapperImage>
            <LazyLoad>
              <img
                src="/images/carousel3.png"
                width="1920"
                height="570"
                alt="Third Carousel"
              />
            </LazyLoad>
          </WrapperImage>
          <WrapperImage>
            <LazyLoad>
              <img
                src="/images/carousel4.png"
                width="1920"
                height="570"
                alt="Fourth Carousel"
              />
            </LazyLoad>
          </WrapperImage>
        </StyledSlider>
        <WrapperContent>
          <p>CAUTA TRANSPORTUL POTRIVIT PENTRU TINE</p>
          <BtnContent to="form">CONTACTEAZA-NE</BtnContent>
        </WrapperContent>
      </CarouselContainer> */}
      <IconsContainerClient>
        <PackageWrapClient>
          <img
            src="/images/package.svg"
            width="65"
            height="65"
            alt="Icon package"
          />
          <p>SERVICII MĂRFURI GENERALE</p>
        </PackageWrapClient>
        <PackageWrapClient>
          <img src="/images/ship.svg" width="65" height="65" alt="Icon ship" />
          <p>SOLUȚII VAMALE</p>
        </PackageWrapClient>
        <PackageWrapClient>
          <img
            src="/images/tools.svg"
            width="65"
            height="65"
            alt="Icon tools"
          />
          <p>PROIECTE PERSONALIZABILE</p>
        </PackageWrapClient>
      </IconsContainerClient>
    </>
  );
};

export default CarouselClient;
