import React from "react";
import styles from "./HeroSection.module.css";
import { Link } from "react-scroll";

const HeroSection1 = () => {
  return (
    <section id="about" className={styles.container}>
      <div className={styles.leftside}>
        <h2>Cine Suntem Noi</h2>
        <img
          className={styles.imageMobile}
          src="/images/hero1.webp"
          alt="Hero section 1"
          width="321"
          height="222"
        />
        <p>
          Suntem Fit Logistics un expeditor român de marfa, fondat pe ideea de a
          deservi companiile sau persoane fizice prin consultanță specializată
          și execuție în servicii de transport de marfă naționale și
          internaționale. „Soluțiile noastre FIT export” unice fac posibilă
          expedierea produselor în întreaga lume. De asemenea, "soluțiile de
          import FIT" facilitează achizițiile internaționale de mărfuri incepand
          de la ridicarea transportului sursa pana la destinatia finală.
          <br />
          <br /> La Fit Logistics, ne angajăm să oferim clienților noștri
          soluții logistice excepționale și inovatoare. Cu acces la unele dintre
          cele mai mari rețele de transport din întreaga lume, oferim un “ghișeu
          unic” pentru toate nevoile dumneavoastră logistice.
        </p>
        <Link className={styles.link} to="form">
          CONTACTEAZĂ-NE
        </Link>
      </div>
      <div className={styles.rightside}>
        <img
          src="/images/hero1.webp"
          width="622"
          height="551"
          alt="Hero section 1"
        />
      </div>
    </section>
  );
};

export default HeroSection1;
