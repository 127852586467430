import styled from "styled-components";
import { Autocomplete } from "@react-google-maps/api";

export const FormMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  font-size: 32px;
  gap: 10px;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;

export const FormMapRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormMapInput = styled.input`
  font-size: clamp(16px, 1.17vw, 20px);
  width: 33%;
  height: 60px;
  border: solid 2px #333;
  background-color: #fff;
  padding-left: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FormMapSelect = styled.select`
  font-size: clamp(16px, 1.19vw, 18px);
  width: 33%;
  height: 60px;
  border: solid 2px #333;
  background-color: #fff;
  padding-left: 15px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FormMapAutoCompleteContainer = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content-center;
    width:33%;
    gap:20px;

    @media screen and (max-width: 768px) {
      flex-direction:column;
      width:100%;
      margin-bottom:5%;
    }
`;

export const FormMapInputAutoComplete = styled.input`
  width: 100%;
  height: 60px;
  border: solid 2px #333;
  background-color: #fff;
  font-size: clamp(16px, 1.19vw, 18px);
  padding-left: 20px;
`;

export const FormMapBottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 90%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormMapButton = styled.button`
  height: 60px;
  background-color: #ff5900;
  color: white;
  border: none;
  border-radius: 9px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: clamp(16px, 1.19vw, 18px);
  cursor: pointer;
  width: 33%;
  font-weight: 700;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 5%;
  }
`;

export const FormMapPrice = styled.div`
  font-size: clamp(16px, 1.19vw, 18px);
  width: 66%;
  height: 60px;
  border: solid 2px #333;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 5%;
  }
`;

export const AutocompleteStyled = styled(Autocomplete)`
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const TitleMap = styled.h1`
  color: #5d358a;
  text-align: center;
  font-size: clamp(30px, 2.6vw, 40px);
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 40px;

  @media screen and (max-width: 768px) {
    margin-top: 3%;
  }
`;

export const FormMapCheckbox = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  font-size: clamp(16px, 0.19vw, 18px);
  font-weight: 700;
  margin-top: 1%;
  margin-bottom: 50px;
  color: #333;

  input {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;
