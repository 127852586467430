import React from "react";
import { Link } from "react-scroll";
import styles from "./HeroSection.module.css";

const HeroSection2 = () => {
  return (
    <section className={styles.container2}>
      <div className={styles.leftside2}>
        <img
          src="/images/hero2.webp"
          width="570"
          height="382"
          alt="Hero section 2"
        />
      </div>
      <div className={styles.rightside2}>
        <h2>De ce suntem POTRIVIȚI?</h2>
        <img
          className={styles.imageMobile}
          src="/images/hero2.webp"
          alt="Hero section 2"
          width="321"
          height="222"
        />
        <p>
          Pentru că esența abordării noastre este crearea de soluții care se
          potrivesc nevoilor clienților noștri.
          <br />
          <br /> În primul rând, ne străduim să analizăm și să înțelegem
          cerințele clienților, înainte de a propune soluții logistice adecvate
          care prioritizează fie timpul de livrare, fie costul, sau soluția care
          găsește echilibrul potrivit între cele două.
        </p>
        <Link className={styles.link} to="form">
          CONTACTEAZĂ-NE
        </Link>
      </div>
    </section>
  );
};

export default HeroSection2;
