import styled from "styled-components";
import { Link } from "react-scroll";
import Slider from "react-slick";

export const CarouselContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const WrapperImage = styled.div`
  img {
    width: 100%;
    height: auto;
  }

  .slick-next {
    display: none !important;
  }
`;

export const WrapperContent = styled.div`
  position: absolute;
  left: 4rem;
  bottom: 2rem;
  z-index: 1;
  color: white;
  font-weight: 700;
  font-size: clamp(16px, 2.65vw, 48px);
  text-align: left;
  width: 40%;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    left: 0;
    justify-content: center;
    align-items: center;
    bottom: 1rem;
    width: 100%;
    text-align: center;
    p {
      width: 75%;
    }
  }
`;

export const BtnContent = styled(Link)`
  font-size: clamp(16px, 1.3vw, 24px);
  font-weight: 700;
  border: 1px solid white;
  border-radius: 8px;
  padding: 0.2em 2em;
  cursor: pointer;
  margin-top: 2%;
`;
export const StyledSlider = styled(Slider)`
  .slick-dots {
    bottom: 20px;
  }

  .slick-dots li button:before {
    font-size: 22px;
    border: 1px solid white;
    border-radius: 50px;
    padding-top: 4px;
    padding-bottom: 1px;
    padding-left: 1.5px;
    padding-right: 4px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #fff;
  }
  .slick-dots li {
    margin: 0 10px;
  }
  @media screen and (max-width: 768px) {
    .slick-dots {
      display: none !important;
    }
  }
`;

export const IconsContainerClient = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 3% auto;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const PackageWrapClient = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: clamp(16px, 1.19vw, 18px);
  text-align: center;
  width: 30%;
  gap: 1rem;
  color: #ff5900;
  img {
    width: 15%;
    height: auto;
  }
`;

export const IconsContainerTransportator = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  padding-top: 30px;
  width: 100%;
  background-color: #f9f8f8;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const PackageWrapTransportator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: clamp(16px, 1.19vw, 18px);
  text-align: center;
  width: 16.5%;
  gap: 1rem;
  color: #ff5900;
  height: 150px;
  img {
    width: 20%;
  }
`;
