import styled from "styled-components";

export const ContainerFooter = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 3px solid #ff5900;
  height: 450px;
  margin-top: 100px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
    height: 600px;
  }
`;

export const LogoFooter = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  height: 80%;
  align-items: flex-start;

  img {
    width: 40%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    order: 2;
    height: auto;
    width: 90%;
    margin: 5% 0;
  }
  @media screen and (max-width: 450px) {
    img {
      width: 100%;
    }
  }
`;

export const RightSideFooter = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  height: 80%;

  @media screen and (max-width: 768px) {
    order: 1;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding-top: 10%;
  }
`;

export const ContactFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2vw;
  width: 50%;
  height: 100%;

  @media screen and (max-width: 768px) {
    gap: 5vw;
    width: 90%;
  }
`;

export const ServicesFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.05vw;
  width: 50%;
  height: 100%;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const TitleFooter = styled.h1`
  color: black;
  font-weight: 700;
  font-size: clamp(18px, 1.25vw, 24px);
`;

export const TextServices = styled.p`
  color: black;
  font-size: clamp(12px, 0.83vw, 16px);

  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 3%;
  }
`;

export const ContainerContactContent = styled.div`
  display: flex;
  color: black;
  font-size: clamp(12px, 0.83vw, 16px);
  align-items: center;

  img {
    width: 5%;
    margin-right: 3%;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const ContainerIcons = styled.div`
  display: flex;
  gap: 2vw;

  img {
    width: 7%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const CopyrightText = styled.p`
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.55);
`;

export const ContainerIconsMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 5%;
  }
`;
