import React from "react";
import { useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  ContactBtn,
  AfterNavContainer,
  AfterNavWrapper,
  AfterNavBtn,
} from "./NavbarElements";

const Navbar = ({ toggle }) => {
  const { pathname } = useLocation();
  const clientPage = pathname === "/";
  const transportatorPage = pathname === "/transportator";

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="home">
            <img
              src="/images/logo.webp"
              width="206"
              height="66"
              alt="Logo Navbar"
            />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavBtn>
            <NavBtnLink
              to="/"
              style={{
                borderBottom: clientPage ? "3px solid #ff5900" : "none",
              }}
            >
              SUNT CLIENT
            </NavBtnLink>
            <NavBtnLink
              to="/transportator"
              style={{
                borderBottom: transportatorPage ? "3px solid #ff5900" : "none",
              }}
            >
              SUNT TRANSPORTATOR
            </NavBtnLink>
            <NavItem>
              <NavLinks to="about">DESPRE NOI</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="contact">CONTACT</NavLinks>
            </NavItem>
          </NavBtn>
          <NavMenu>
            <NavItem>
              <ContactBtn to="form">CONTACTEAZA-NE</ContactBtn>
            </NavItem>
            <div
              className="languages"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
                cursor: "pointer",
              }}
            >
              <img
                src="/images/ro.webp"
                width="25"
                height="25"
                alt="ro language"
              />
              <img
                src="/images/eng.webp"
                width="25"
                height="25"
                alt="eng language"
              />
            </div>
          </NavMenu>
        </NavbarContainer>
      </Nav>
      <AfterNavContainer>
        <AfterNavWrapper>
          <AfterNavBtn
            to="/"
            style={{
              backgroundColor: clientPage ? "#ff5900" : "white",
              color: clientPage ? "white" : "#ff5900",
            }}
          >
            Sunt Client
          </AfterNavBtn>
        </AfterNavWrapper>
        <AfterNavWrapper>
          <AfterNavBtn
            to="/transportator"
            style={{
              backgroundColor: transportatorPage ? "#ff5900" : "white",
              color: transportatorPage ? "white" : "#ff5900",
            }}
          >
            Sunt Transportator
          </AfterNavBtn>
        </AfterNavWrapper>
      </AfterNavContainer>
    </>
  );
};

export default Navbar;
