import React, { useState, useCallback, useRef } from "react";
import {
  GoogleMap,
  LoadScriptNext,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  FormMapAutoCompleteContainer,
  FormMapContainer,
  FormMapInput,
  FormMapInputAutoComplete,
  FormMapRow,
  FormMapSelect,
  FormMapBottomRow,
  FormMapButton,
  FormMapPrice,
  AutocompleteStyled,
  TitleMap,
  FormMapCheckbox,
} from "./GoogleMapsElements";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styles from "./GoogleMaps.module.css";

const containerStyle = {
  width: "90%",
  height: "400px",
  margin: "1% auto",
  borderRadius: "10px",
};

const center = {
  lat: 44.4268,
  lng: 26.1025,
};
const libraries1 = ["places"];
const GoogleMapComponent = () => {
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [directions, setDirections] = useState(null);
  const [price, setPrice] = useState(null);
  const [checkBox, setCheckBox] = useState(false);
  const originRef = useRef(null);
  const destinationRef = useRef(null);
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [formDetails, setFormDetails] = useState({
    nume: "",
    email: "",
    telefon: "",
    vehicul: "",
    marfa: "",
  });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };

  const calculatePrice = (distanceInKm, vehicul, marfa) => {
    const prices = {
      Camion: {
        MarfăGeneralăPaletizată: 6,
        Frigorifică: 6.5,
        Utilaje: 7,
        Colete: 6,
      },
      ExpresVanînchis: {
        MarfăGeneralăPaletizată: 2.5,
        Frigorifică: 3,
        Utilaje: 3,
        Colete: 3,
      },
      ExpresVanPrelată: {
        MarfăGeneralăPaletizată: 2.5,
        Frigorifică: 3,
        Utilaje: 3,
        Colete: 3,
      },
      ExpresTailLift: {
        MarfăGeneralăPaletizată: 3.5,
        Frigorifică: 3.5,
        Utilaje: 3.5,
        Colete: 3.5,
      },
    };

    const vehiculPrice = prices[vehicul] ? prices[vehicul][marfa] : 0;
    const totalPrice = distanceInKm * vehiculPrice;

    return totalPrice;
  };

  const calculateAndDisplayRoute = () => {
    const { nume, email, telefon, vehicul, marfa } = formDetails;

    // Validation for 'vehicul' and 'marfa'
    if (
      !origin ||
      !destination ||
      !nume ||
      !email ||
      !telefon ||
      !vehicul ||
      !marfa ||
      !checkBox
    ) {
      alert("Vă rugăm să completați toate câmpurile.");
      setIsLoading(false);
      return false;
    }
    const directionsService = new window.google.maps.DirectionsService();
    const originLatLng = originRef.current.getPlace().geometry.location;
    const destinationLatLng =
      destinationRef.current.getPlace().geometry.location;

    const europeBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(34.56, -10.89),
      new window.google.maps.LatLng(71.18, 44.82)
    );

    if (
      !europeBounds.contains(originLatLng) ||
      !europeBounds.contains(destinationLatLng)
    ) {
      alert("Vă rugăm să selectați orașe înăuntrul Europei.");
      setOrigin("");
      setDestination("");
      return false;
    }
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        avoidTolls: true,
      },
      async (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);

          const route = result.routes[0].legs[0];
          const distanceInKm = route.distance.value / 1000;
          const calculatedPrice = calculatePrice(
            distanceInKm,
            formDetails.vehicul,
            formDetails.marfa
          );
          setPrice(calculatedPrice);
        } else {
          console.error(`Error fetching directions. Status: ${status}`);
        }
      }
    );
    setOrigin("");
    setDestination("");
    setFormDetails({
      nume: "",
      email: "",
      telefon: "",
      vehicul: "",
      marfa: "",
    });
    setCheckBox(false);
    return true;
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Get reCAPTCHA token
    const recaptchaToken = await executeRecaptcha("submitForm");

    if (recaptchaToken) {
      // Validation for 'nume', 'email', and 'telefon'
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^\d{8,15}$/;
      const nameRegex = /^[a-zA-Z\s]+$/;

      if (!nameRegex.test(formDetails.nume)) {
        alert("Vă rugăm să introduceți un nume valid.");
        setIsLoading(false);
        return;
      }

      if (!emailRegex.test(formDetails.email)) {
        alert("Vă rugăm să introduceți un email valid.");
        setIsLoading(false);
        return;
      }

      if (!phoneRegex.test(formDetails.telefon)) {
        alert("Vă rugăm să introduceți un număr de telefon valid.");
        setIsLoading(false);
        return;
      }
      if (calculateAndDisplayRoute()) {
        const formData = new FormData(formRef.current);
        formData.append("access_key", process.env.REACT_APP_WEB3_KEY);
        // formData.append("g-recaptcha-response", recaptchaToken); // Append the reCAPTCHA token to the form data

        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData,
        }).then((res) => res.json());

        if (res.success) {
          setModalOpen(true);
          setIsLoading(false);
        } else {
          console.log("Error", res);
          alert("A apărut o eroare, vă rugăm să încercați mai târziu!");
          setIsLoading(false);
        }
      }
    } else {
      // If no reCAPTCHA token, notify the user of an issue
      alert(
        "A apărut o eroare la verificarea reCAPTCHA. Vă rugăm să încercați din nou."
      );
    }
  };

  const handleOriginAutoComplete = useCallback((autocomplete) => {
    originRef.current = autocomplete;
  }, []);

  const handleDestinationAutoComplete = useCallback((autocomplete) => {
    destinationRef.current = autocomplete;
  }, []);

  const handleOriginPlaceChanged = () => {
    const place = originRef.current.getPlace();
    setOrigin(place.formatted_address);
  };

  const handleDestinationPlaceChanged = () => {
    const place = destinationRef.current.getPlace();
    setDestination(place.formatted_address);
  };

  const options = {
    region: ".eu",
    language: "ro",
  };

  const mapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
  };

  return (
    <div className={styles.containerGoogleMaps}>
      <TitleMap>CALCULEAZĂ CURSA</TitleMap>
      <LoadScriptNext
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={libraries1}
        language="ro"
      >
        <form onSubmit={submitForm} ref={formRef}>
          <FormMapContainer height="560">
            <input
              type="hidden"
              name="subject"
              value="New distance calculated"
            ></input>
            <input
              type="checkbox"
              name="botcheck"
              className="hidden"
              style={{ display: "none" }}
            ></input>
            <FormMapRow>
              <FormMapInput
                type="text"
                name="nume"
                placeholder="Nume"
                value={formDetails.nume}
                onChange={handleChange}
                required
              />
              <FormMapInput
                type="email"
                name="email"
                placeholder="Email"
                value={formDetails.email}
                onChange={handleChange}
                required
              />
              <FormMapInput
                type="tel"
                name="telefon"
                placeholder="Numar de telefon"
                value={formDetails.telefon}
                onChange={handleChange}
                pattern="\d{8,15}"
                required
              />
            </FormMapRow>
            <FormMapRow>
              <FormMapSelect
                name="vehicul"
                value={formDetails.vehicul}
                onChange={handleChange}
                required
              >
                <option value="">Alege tipul de vehicul</option>
                <option value="Camion">Camion</option>
                <option value="ExpresVanÎnchis">Expres-Van închis</option>
                <option value="ExpresVanPrelată">Expres-Van prelată</option>
                <option value="ExpresTailLift">Expres-Tail lift</option>
              </FormMapSelect>
              <FormMapSelect
                name="marfa"
                value={formDetails.marfa}
                onChange={handleChange}
                required
              >
                <option value="">Alege tipul de marfă</option>
                <option value="MarfăGeneralăPaletizată">
                  Marfă generală paletizată
                </option>
                <option value="Utilaje">Utilaje</option>
                <option value="Colete">Colete</option>
                <option value="Frigorifică">Frigorifică</option>
              </FormMapSelect>
              <FormMapAutoCompleteContainer
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AutocompleteStyled
                  onLoad={handleOriginAutoComplete}
                  onPlaceChanged={handleOriginPlaceChanged}
                  options={options}
                >
                  <FormMapInputAutoComplete
                    type="text"
                    placeholder="DE LA"
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                    required
                  />
                </AutocompleteStyled>

                <AutocompleteStyled
                  onLoad={handleDestinationAutoComplete}
                  onPlaceChanged={handleDestinationPlaceChanged}
                  options={options}
                >
                  <FormMapInputAutoComplete
                    type="text"
                    placeholder="PÂNĂ LA"
                    value={destination}
                    onChange={(e) => setDestination(e.target.value)}
                    required
                  />
                </AutocompleteStyled>
              </FormMapAutoCompleteContainer>
            </FormMapRow>
            <input type="hidden" name="origin" value={origin} />
            <input type="hidden" name="destination" value={destination} />
          </FormMapContainer>
          <GoogleMap
            style={{ height: "400px" }}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={5}
            options={mapOptions}
          >
            {directions && <DirectionsRenderer directions={directions} />}
          </GoogleMap>
          <FormMapBottomRow>
            <FormMapPrice>
              <p>PREȚ ESTIMATIV: {price ? price.toFixed(2) : 0} LEI</p>
            </FormMapPrice>
            <FormMapButton onClick={submitForm} disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : "SOLICITĂ OFERTĂ"}
            </FormMapButton>
          </FormMapBottomRow>
          <FormMapCheckbox>
            <input
              type="checkbox"
              checked={checkBox}
              onChange={() => setCheckBox(!checkBox)}
            />
            Am luat la cunoștință că acest cost este estimativ și nu reflectă
            costul exact și sunt de acord cu prelucrarea datelor personale.
          </FormMapCheckbox>
        </form>
      </LoadScriptNext>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.box}>
          <div className={styles.wrapper_modal}>
            <Typography
              className={styles.modal_text}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Felicitări!
            </Typography>
            <Typography
              className={styles.modal_text}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              O să te contactăm în curând!
            </Typography>
            <button
              className={styles.previous_button}
              type="button"
              onClick={() => setModalOpen(false)}
            >
              Înapoi
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default GoogleMapComponent;
