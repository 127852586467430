import React from "react";
import {
  ContainerFooter,
  LogoFooter,
  RightSideFooter,
  ContactFooter,
  TitleFooter,
  ContainerContactContent,
  ServicesFooter,
  TextServices,
  ContainerIcons,
  CopyrightText,
  ContainerIconsMobile,
} from "./FooterElements";

const Footer = () => {
  return (
    <>
      <ContainerFooter id="contact">
        <LogoFooter>
          <img
            src="/images/logo.webp"
            width="265"
            height="83"
            alt="Logo footer"
          />
        </LogoFooter>
        <RightSideFooter>
          <ContactFooter>
            <TitleFooter>CONTACT</TitleFooter>
            <ContainerContactContent>
              <img
                width="17"
                height="25"
                src="/images/pinfooter.svg"
                alt="Pin icon footer"
              />
              <p>Str. Raul Doamnei , nr 15 , Bragadiru , Ilfov</p>
            </ContainerContactContent>
            <ContainerContactContent>
              <img
                width="17"
                height="25"
                src="/images/emailfooter.svg"
                alt="Email icon footer"
              />
              <p>contact@fitlogistics.ro</p>
            </ContainerContactContent>
            <ContainerContactContent>
              <img
                width="17"
                height="25"
                src="/images/telfooter.svg"
                alt="Tel icon footer"
              />
              <p>+40729565200</p>
            </ContainerContactContent>
            <ContainerIcons>
              <img
                src="/images/facebook.svg"
                width="17"
                height="25"
                alt="Facebook icon"
              />
              <img
                src="/images/linkedin.svg"
                width="17"
                height="25"
                alt="LinkedIn icon"
              />
              <img
                src="/images/whatsapp.svg"
                width="17"
                height="25"
                alt="Whatsapp icon"
              />
            </ContainerIcons>
          </ContactFooter>
          <ServicesFooter>
            <TitleFooter>SERVICII</TitleFooter>
            <TextServices>Proiecte speciale</TextServices>
            <TextServices>Consultanță logistică</TextServices>
            <TextServices>Soluții servicii vamale</TextServices>
            <TextServices>Expediere mărfuri generale în România</TextServices>
            <TextServices>Expediere mărfuri generale în EU</TextServices>
          </ServicesFooter>
        </RightSideFooter>
      </ContainerFooter>
      <ContainerIconsMobile>
        <img
          src="/images/facebook.svg"
          width="17"
          height="25"
          alt="Facebook icon"
        />
        <img
          src="/images/linkedin.svg"
          width="17"
          height="25"
          alt="LinkedIn icon"
        />
        <img
          src="/images/whatsapp.svg"
          width="17"
          height="25"
          alt="Whatsapp icon"
        />
      </ContainerIconsMobile>
      <CopyrightText>
        &copy; 2023 FitLogistics. All rights reserved.
      </CopyrightText>
    </>
  );
};

export default Footer;
