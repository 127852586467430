import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styles from "./ComplexFormClient.module.css";
import { AiOutlineRight } from "react-icons/ai";
import { AiOutlineLeft } from "react-icons/ai";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import CircularProgress from "@mui/material/CircularProgress";
import "animate.css";

const ComplexFormClient = () => {
  const { control, handleSubmit, reset } = useForm();
  const [step, setStep] = useState(0);
  const [mainOption, setMainOption] = useState(null);
  const [marfaType, setMarfaType] = useState(null);
  const [palletOption, setPalletOption] = useState(null);
  const [palletType, setPalletType] = useState(null);
  const [isContactInfoStep, setIsContactInfoStep] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formRef = useRef(null);
  const [inputType, setInputType] = useState("text");
  const [inputType2, setInputType2] = useState("text");

  const onSubmit = async (data) => {
    if (isContactInfoStep) {
      setIsLoading(true);
      // Get reCAPTCHA token
      const recaptchaToken = await executeRecaptcha("submitForm");

      if (recaptchaToken) {
        const newData = {
          optiune_camion: data.mainOption,
          tip_marfa: data.marfaType,
          greutate: data.weight,
          de_la: data.from,
          pana_la: data.to,
          data_preluare: data.pickupDate,
          data_sosire: data.arrivalDate,
          optiune_repartizare: data.palletOption,
          tip_paleti: data.palletType,
          numar_paleti: data.numberOfPallets,
          latime_paleti: data.latime,
          lungime_paleti: data.lungime,
          metri_podea: data.floorMeters,
          termodiagrama: data.thermodiagram,
          grade_celsius: data.celsiusGrade,
          nume: data.name,
          email: data.email,
          nume_companie: data.companyName,
          telefon: data.phone,
          tip_paleti_express_van_inchis: data.palletTypeVanInchis,
        };
        const filteredData = Object.fromEntries(
          Object.entries(newData).filter(([_, value]) => value !== undefined)
        );

        const formData = new FormData(formRef.current);
        formData.append("access_key", process.env.REACT_APP_WEB3_KEY);
        // formData.append("g-recaptcha-response", recaptchaToken); // Append the reCAPTCHA token to the form data
        Object.entries(filteredData).forEach(([key, value]) => {
          formData.append(key, value);
        });

        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData,
        }).then((res) => res.json());

        if (res.success) {
          reset();
          setStep(0);
          setModalOpen(true);
          setIsLoading(false);
        } else {
          console.log("Error", res);
          alert("A apărut o eroare, vă rugăm să încercați mai târziu!");
          setIsLoading(false);
        }
      } else {
        // If no reCAPTCHA token, notify the user of an issue
        alert(
          "A apărut o eroare la verificarea reCAPTCHA. Vă rugăm să încercați din nou."
        );
      }
    } else {
      setStep(step + 1);
    }
  };

  const onPrevious = () => {
    setStep(step - 1);
  };

  const Step1 = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label htmlFor="mainOption" className={styles.question_step}>
        Ce tip de auto vrei pentru transportul tau?
      </label>
      <p className={styles.text_step}>
        Alegerea se face în funcție de greutate, dimensiuni și tipul de marfă.
      </p>
      <div className={styles.wrapper_step1}>
        {[
          { value: "camion", label: "Camion", path: "/images/camion.webp" },
          {
            value: "express_van_inchis",
            label: "Express Van inchis",
            path: "/images/van-inchis.webp",
          },
          {
            value: "express_prelata",
            label: "Express prelata",
            path: "/images/prelata.webp",
          },
          {
            value: "express_tail_lift",
            label: "Express tail lift",
            path: "/images/tail.webp",
          },
        ].map((option) => (
          <Controller
            key={option.value}
            name="mainOption"
            control={control}
            defaultValue={false}
            rules={{ required: true }}
            render={({ field }) => (
              <label className={styles.label_step1}>
                <input
                  type="radio"
                  value={option.value}
                  checked={field.value === option.value}
                  onChange={(e) => {
                    field.onChange(option.value);
                    setMainOption(option.value);
                    setStep(step + 1);
                  }}
                />
                <img src={option.path} alt="truck type" />
                <div className={styles.wrapper_labels}>
                  {option.label}
                  <AiOutlineRight />
                </div>
              </label>
            )}
          />
        ))}
      </div>
    </div>
  );

  const Step2Camion = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label htmlFor="marfaType" className={styles.question_step}>
        Ce tip de marfa vrei sa transporti in camion?
      </label>
      <div className={styles.wrapper_step1}>
        {[
          {
            value: "marfa_generalizata_paletizata",
            label: "Paletizata",
            path: "/images/paletizata.png",
          },
          { value: "utilaje", label: "Utilaje", path: "/images/utilaje.png" },
          {
            value: "frigorifica",
            label: "Frigorifica",
            path: "/images/frigorifica.png",
          },
        ].map((option) => (
          <Controller
            key={option.value}
            name="marfaType"
            control={control}
            defaultValue={false}
            rules={{ required: true }}
            render={({ field }) => (
              <label className={styles.label_step1}>
                <input
                  type="radio"
                  value={option.value}
                  checked={field.value === option.value}
                  onChange={(e) => {
                    field.onChange(option.value);
                    setMarfaType(option.value);
                    setStep(step + 1);
                  }}
                />
                <img src={option.path} alt="marfa type" />
                <div className={styles.wrapper_labels}>
                  {option.label}
                  <AiOutlineRight />
                </div>
              </label>
            )}
          />
        ))}
      </div>
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
      </div>
    </div>
  );

  const Step3CommonQuestion1 = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="weight">
        Ce greutate aproximativa are marfa ta?
      </label>
      <p className={styles.text_step}>
        Un camion permite o încărcătura de 1800 de kg pe metru de podea, astfel
        că calculul metrilor de podea se va face și în funcție de greutate.
      </p>
      <Controller
        name="weight"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            type="number"
            placeholder="Scrie greutatea (maxim 24000kg)"
            {...field}
          />
        )}
      />
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
        <button className={styles.continue_button} type="submit">
          <>
            Pasul Următor <AiOutlineRight />
          </>
        </button>
      </div>
    </div>
  );

  const WeightExpressVanInchis = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="weight">
        Ce greutate aproximativa are marfa ta?
      </label>
      <Controller
        name="weight"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            type="number"
            placeholder="Scrie greutatea (maxim 1200kg)"
            {...field}
          />
        )}
      />
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
        <button className={styles.continue_button} type="submit">
          <>
            Pasul Următor <AiOutlineRight />
          </>
        </button>
      </div>
    </div>
  );

  const WeightExpressTailLift = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="weight">
        Ce greutate aproximativa are marfa ta?
      </label>
      <Controller
        name="weight"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            type="number"
            placeholder="Scrie greutatea (maxim 800kg)"
            {...field}
          />
        )}
      />
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
        <button className={styles.continue_button} type="submit">
          <>
            Pasul Următor <AiOutlineRight />
          </>
        </button>
      </div>
    </div>
  );

  const WeightExpressPrelata = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="weight">
        Ce greutate aproximativa are marfa ta?
      </label>
      <Controller
        name="weight"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            type="number"
            placeholder="Scrie greutatea (maxim 1000kg)"
            {...field}
          />
        )}
      />
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
        <button className={styles.continue_button} type="submit">
          <>
            Pasul Următor <AiOutlineRight />
          </>
        </button>
      </div>
    </div>
  );

  const Step4CommonQuestion2 = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="details">
        Detaliile cursei
      </label>
      <Controller
        name="from"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            {...field}
            placeholder="De la"
          />
        )}
      />

      <Controller
        name="to"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            {...field}
            placeholder="Pana la"
          />
        )}
      />

      <Controller
        name="pickupDate"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            {...field}
            type={inputType}
            onFocus={() => setInputType("date")}
            onBlur={() => setInputType("text")}
            placeholder={inputType === "text" ? "Data de prelucrare" : ""}
          />
        )}
      />

      <Controller
        name="arrivalDate"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            {...field}
            type={inputType2}
            onFocus={() => setInputType2("date")}
            onBlur={() => setInputType2("text")}
            placeholder={inputType2 === "text" ? "Data de sosire" : ""}
          />
        )}
      />
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
        <button className={styles.continue_button} type="submit">
          <>
            Pasul Următor <AiOutlineRight />
          </>
        </button>
      </div>
    </div>
  );

  const Step5SpecificQuestion = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="palletOption">
        Vrei sa introduci numar de paleti si dimensiuni sau metri de podea?
      </label>
      {[
        { value: "paleti", label: "Vreau numar de paleti si dimensiuni" },
        { value: "podea", label: "Vreau metri de podea" },
        { value: "full", label: "Vreau camion complet" },
      ].map((option) => (
        <Controller
          key={option.value}
          name="palletOption"
          control={control}
          defaultValue={false}
          rules={{ required: true }}
          render={({ field }) => (
            <label className={styles.input_radio_step}>
              <input
                type="radio"
                value={option.value}
                checked={field.value === option.value}
                onChange={(e) => {
                  field.onChange(option.value);
                  setPalletOption(option.value);
                  setStep(step + 1);
                }}
              />
              {option.label}
            </label>
          )}
        />
      ))}
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
      </div>
    </div>
  );

  const Step7PalletType = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="palletType">
        Ce tipuri de paleti?
      </label>
      {[
        { value: "EWP", label: "EWP - STANDARD EUROPALETI (120 x 80 cm)" },
        { value: "UK", label: "UK STANDARD (120 x 100 cm)" },
        { value: "INDUSTRIAL", label: "INDUSTRIALI (120 x 120 cm)" },
        { value: "ATIPICI", label: "ATIPICI" },
      ].map((option) => (
        <Controller
          key={option.value}
          name="palletType"
          control={control}
          defaultValue={false}
          rules={{ required: true }}
          render={({ field }) => (
            <label className={styles.input_radio_step}>
              <input
                type="radio"
                value={option.value}
                checked={field.value === option.value}
                onChange={(e) => {
                  field.onChange(option.value);
                  setPalletType(option.value);
                  setStep(step + 1);
                }}
              />
              {option.label}
            </label>
          )}
        />
      ))}
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
      </div>
    </div>
  );

  const VanInchisType = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="palletTypeVanInchis">
        Ce tipuri de paleti?
      </label>
      {[
        { value: "5_euro_paleti", label: "3.7 x 1.7 x 1.7 - 5 Euro Paleti" },
        { value: "7_euro_paleti", label: "4.64 x 1.7 x 1.8 - 7 Euro Paleti" },
      ].map((option) => (
        <Controller
          key={option.value}
          name="palletTypeVanInchis"
          control={control}
          defaultValue={false}
          rules={{ required: true }}
          render={({ field }) => (
            <label className={styles.input_radio_step}>
              <input
                type="radio"
                value={option.value}
                checked={field.value === option.value}
                onChange={(e) => {
                  field.onChange(option.value);
                  setPalletType(option.value);
                  setStep(step + 1);
                }}
              />
              {option.label}
            </label>
          )}
        />
      ))}
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
      </div>
    </div>
  );

  const Step8NumberOfPallets = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="numberOfPallets">
        Ce numar de paleti vrei sa transporti?
      </label>
      <Controller
        name="numberOfPallets"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            type="number"
            placeholder="Introdu numarul de paleti"
            {...field}
          />
        )}
      />
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
        <button className={styles.continue_button} type="submit">
          <>
            Pasul Următor <AiOutlineRight />
          </>
        </button>
      </div>
    </div>
  );

  const Step9AtipiciDimensions = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="atipiciDimensions">
        Ce dimensiuni sa aiba paletii?
      </label>
      <Controller
        name="latime"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            type="number"
            placeholder="Latime"
            {...field}
          />
        )}
      />
      <Controller
        name="lungime"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            type="number"
            placeholder="Lungime"
            {...field}
          />
        )}
      />
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
        <button className={styles.continue_button} type="submit">
          <>
            Pasul Următor <AiOutlineRight />
          </>
        </button>
      </div>
    </div>
  );

  const StepXFloorMeters = () => {
    const marks = [
      {
        value: 2,
        label: "2",
      },
      {
        value: 3,
        label: "3",
      },
      {
        value: 4,
        label: "4",
      },
      {
        value: 5,
        label: "5",
      },
      {
        value: 6,
        label: "6",
      },
      {
        value: 7,
        label: "7",
      },
      {
        value: 8,
        label: "8",
      },
      {
        value: 9,
        label: "9",
      },
      {
        value: 10,
        label: "10",
      },
      {
        value: 11,
        label: "11",
      },
      {
        value: 12,
        label: "12",
      },
      {
        value: 13,
        label: "13",
      },
    ];

    const Input = styled(MuiInput)`
      width: 70px;
    `;
    const [value, setValue] = React.useState(2.5);

    const handleBlur = () => {
      if (value < 0) {
        setValue(0);
      } else if (value > 13) {
        setValue(13);
      }
    };

    return (
      <div
        className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
      >
        <label className={styles.question_step} htmlFor="floorMeters">
          Introduceti numarul de metri de podea pe care ii doriti:
        </label>
        <Controller
          name="floorMeters"
          control={control}
          defaultValue={2.5}
          rules={{ required: true }}
          render={({ field }) => {
            const handleSliderChange = (event, newValue) => {
              setValue(newValue);
              field.onChange(newValue);
            };

            const handleInputChange = (event) => {
              const newValue =
                event.target.value === "" ? "" : Number(event.target.value);
              setValue(newValue);
              field.onChange(newValue);
            };

            return (
              <div className={styles.container_slider}>
                <Slider
                  className={styles.input_slider_step}
                  value={typeof value === "number" ? value : 0}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  step={0.5}
                  marks={marks}
                  min={2}
                  max={13}
                  valueLabelDisplay="on"
                />
                <div className={styles.input_slider_number}>
                  <Input
                    value={value}
                    size="large"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    inputProps={{
                      step: 0.5,
                      min: 2,
                      max: 13,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </div>
              </div>
            );
          }}
        />
        <div className={styles.container_buttons}>
          <button
            className={styles.previous_button}
            type="button"
            onClick={onPrevious}
          >
            <AiOutlineLeft /> Înapoi
          </button>
          <button className={styles.continue_button} type="submit">
            <>
              Pasul Următor <AiOutlineRight />
            </>
          </button>
        </div>
      </div>
    );
  };

  const StepCelsiusGrade = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="celsiusGrade">
        Grade Celsius (max 20, min -25)
      </label>
      <Controller
        name="celsiusGrade"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <input
            className={styles.input_text_step}
            type="number"
            placeholder="Introduceti grade Celsius"
            {...field}
          />
        )}
      />
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
        <button className={styles.continue_button} type="submit">
          <>
            Pasul Următor <AiOutlineRight />
          </>
        </button>
      </div>
    </div>
  );

  const StepThermodiagram = () => (
    <div
      className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
    >
      <label className={styles.question_step} htmlFor="thermodiagram">
        Ai nevoie de termodiagrama?
      </label>
      {[
        { value: "da", label: "Da" },
        { value: "nu", label: "Nu" },
      ].map((option) => (
        <Controller
          key={option.value}
          name="thermodiagram"
          control={control}
          defaultValue={false}
          rules={{ required: true }}
          render={({ field }) => (
            <label className={styles.input_radio_step}>
              <input
                type="radio"
                value={option.value}
                checked={field.value === option.value}
                onChange={(e) => {
                  field.onChange(option.value);
                  setStep(step + 1);
                }}
              />
              {option.label}
            </label>
          )}
        />
      ))}
      <div className={styles.container_buttons}>
        <button
          className={styles.previous_button}
          type="button"
          onClick={onPrevious}
        >
          <AiOutlineLeft /> Înapoi
        </button>
      </div>
    </div>
  );

  const Step6ContactInfo = () => {
    useEffect(() => {
      setIsContactInfoStep(true);
      return () => {
        setIsContactInfoStep(false);
      };
    }, []);

    return (
      <div
        className={`animate__animated animate__fadeInLeft ${styles.container_step}`}
      >
        <label className={styles.question_step} htmlFor="contactInfo">
          Tot ce mai avem nevoie este sa ne dai datele tale de contact unde sa
          iti trimitem oferta.
        </label>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <input
              className={styles.input_text_step}
              {...field}
              placeholder="Nume"
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <input
              className={styles.input_text_step}
              type="email"
              {...field}
              placeholder="Email"
            />
          )}
        />
        <Controller
          name="companyName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              className={styles.input_text_step}
              {...field}
              placeholder="Nume Companie"
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <input
              className={styles.input_text_step}
              type="tel"
              {...field}
              pattern="\d{8,15}"
              placeholder="Telefon"
            />
          )}
        />
        <div className={styles.container_buttons}>
          <button
            className={styles.previous_button}
            type="button"
            onClick={onPrevious}
          >
            <AiOutlineLeft /> Înapoi
          </button>
          <button className={styles.continue_button} type="submit">
            {isLoading ? <CircularProgress size={24} /> : "Trimite"}
            <AiOutlineRight />
          </button>
        </div>
      </div>
    );
  };

  const calculateProgress = () => {
    let totalSteps;
    let currentStep;

    if (mainOption === "camion") {
      if (marfaType === "frigorifica") {
        totalSteps = 7;
      } else if (marfaType === "marfa_generalizata_paletizata") {
        if (palletOption === "paleti") {
          if (palletType === "ATIPICI") {
            totalSteps = 9;
          } else {
            totalSteps = 8;
          }
        } else if (palletOption === "podea") {
          totalSteps = 7;
        } else if (palletOption === "full") {
          totalSteps = 6;
        } else {
          totalSteps = 7;
        }
      } else if (marfaType === "utilaje") {
        totalSteps = 5;
      } else {
        totalSteps = 5;
      }
      currentStep = step + 1;
    } else if (mainOption === "express_van_inchis") {
      totalSteps = 5;
      currentStep = step + 1;
    } else {
      totalSteps = 3;
      currentStep = step;
    }

    return (currentStep / totalSteps) * 100;
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <Step1 />;
      case 1:
        if (mainOption === "camion") {
          return <Step2Camion />;
        } else if (mainOption === "express_van_inchis") {
          return <WeightExpressVanInchis />;
        } else if (mainOption === "express_prelata") {
          return <WeightExpressPrelata />;
        } else return <WeightExpressTailLift />;
      case 2:
        if (mainOption === "express_van_inchis") {
          return <VanInchisType />;
        } else if (mainOption === "camion") {
          return <Step3CommonQuestion1 />;
        } else return <Step4CommonQuestion2 />;
      case 3:
        if (mainOption === "camion" || mainOption === "express_van_inchis") {
          return <Step4CommonQuestion2 />;
        } else return <Step6ContactInfo />;
      case 4:
        if (mainOption === "camion" && marfaType === "frigorifica") {
          return <StepCelsiusGrade />;
        } else if (
          mainOption === "camion" &&
          marfaType === "marfa_generalizata_paletizata"
        ) {
          return <Step5SpecificQuestion />;
        } else if (mainOption === "camion" && marfaType === "utilaje") {
          return <Step6ContactInfo />;
        } else if (palletOption === "full") {
          return <Step6ContactInfo />;
        } else {
          return <Step6ContactInfo />;
        }
      case 5:
        if (mainOption === "camion" && marfaType === "frigorifica") {
          return <StepThermodiagram />;
        } else if (palletOption === "paleti") {
          return <Step7PalletType />;
        } else if (palletOption === "podea") {
          return <StepXFloorMeters />;
        } else {
          return <Step6ContactInfo />;
        }
      case 6:
        if (palletOption === "paleti") {
          if (palletType === "ATIPICI") {
            return <Step9AtipiciDimensions />;
          } else {
            return <Step8NumberOfPallets />;
          }
        } else {
          return <Step6ContactInfo />;
        }
      case 7:
        if (palletOption === "paleti" && palletType === "ATIPICI") {
          return <Step8NumberOfPallets />;
        } else {
          return <Step6ContactInfo />;
        }
      case 8:
        return <Step6ContactInfo />;
      default:
        return <Step6ContactInfo />;
    }
  };

  return (
    <div id="form" className="container">
      <div className={styles.progressBar}>
        <div
          className={styles.progressBarInner}
          style={{ width: `${calculateProgress()}%` }}
        />
      </div>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        {renderStep()}
      </form>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.box}>
          <div className={styles.wrapper_modal}>
            <Typography
              className={styles.modal_text}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Felicitări!
            </Typography>
            <Typography
              className={styles.modal_text}
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              O să te contactăm în curând!
            </Typography>
            <button
              className={styles.previous_button}
              type="button"
              onClick={() => setModalOpen(false)}
            >
              <AiOutlineLeft /> Înapoi
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ComplexFormClient;
